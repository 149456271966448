import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="zip-search"
export default class extends Controller {
  static targets = ['zipcode', 'prefecture', 'city']

  search() {
    const url = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
    let zipcode = this.zipcodeTarget.value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    zipcode = zipcode.replace(/[‐－―ー]/g, '-');

    fetch(url + zipcode, {
        method: "GET",
        mode: "cors"
      })
        .then(response => response.json())
        .then(responseData => {
          if (!responseData.results) {
            return;
          }
          const data = responseData.results[0]
          this.prefectureTarget.value = data.address1
          this.cityTarget.value = data.address2
        });
  }
}