import { Controller } from '@hotwired/stimulus';

var $ = require('jquery');

// Connects to data-controller='form--custom-form-template-confirm'
export default class extends Controller {
  static targets = ['form', 'selectInputTemplate', 'selectCompleteTemplate', 'selectSurveyTemplate']
  static values = {
    'current-input': String,
    'current-complete': String,
    'current-survey': String,
    'message': String,
  }

  submit() {
    const onConfirm = this.checkNeedConfirm()
    const onSubmit = !onConfirm || confirm(this.messageValue)

    if (onSubmit) {
      this.formTarget.submit();
    }
  }

  private checkNeedConfirm() {
    const changeInputTemplate = this.selectInputTemplateTarget.value
    const changeCompleteTemplate = this.selectCompleteTemplateTarget.value
    const changeSurveyTemplate = this.selectSurveyTemplateTarget.value

    const currentInputTemplate = this.currentInputValue
    const currentCompleteTemplate = this.currentCompleteValue
    const currentSurveyTemplate = this.currentSurveyValue

    const inputTemplateConfirm = currentInputTemplate && changeInputTemplate
    const completeTemplateConfirm =  currentCompleteTemplate && changeCompleteTemplate
    const surveyTemplateConfirm = currentSurveyTemplate && changeSurveyTemplate

    return inputTemplateConfirm || completeTemplateConfirm || surveyTemplateConfirm
  }
}
