import { application } from './application';

import HelloController from './hello_controller';
application.register('hello', HelloController);

import Select2Controller from "./select2_controller"
application.register("select2", Select2Controller)

import ToggleDisclosureController from "./toggle-disclosure_controller"
application.register("toggle-disclosure", ToggleDisclosureController)

import InlineAttachmentController from "./inline-attachment_controller"
application.register("inline-attachment", InlineAttachmentController)

import PostPardotController from "./post-pardot_controller"
application.register("post-pardot", PostPardotController)

import SurveybuilderController from "./survey/builder_controller"
application.register("survey--builder", SurveybuilderController)

import ModalController from "./modal_controller"
application.register("modal", ModalController)

import ZipSearchController from "./zip_search_controller"
application.register("zip-search", ZipSearchController)

import AdditionalInputFormController from "./additional-input-form_controller"
application.register("additional-input-form", AdditionalInputFormController)

import RemoveInputFormController from "./remove-input-form_controller"
application.register("remove-input-form", RemoveInputFormController)

import ToggleRequiredController from "./toggle-required_controller"
application.register("toggle-required", ToggleRequiredController)

import TogglePasswordController from "./toggle-password_controller"
application.register("toggle-password", TogglePasswordController)

import FormSubmitController from './form-submit_controller'
application.register('form-submit', FormSubmitController)

import EntryFileSelectController from './entry/file-select_controller'
application.register('entry--file-select', EntryFileSelectController)

import FormArticleTemplateConfirmController from './form/article_template_confirm_controller'
application.register('form--article-template-confirm', FormArticleTemplateConfirmController)

import FormCustomFormTemplateConfirmController from './form/custom_form_template_confirm_controller'
application.register('form--custom-form-template-confirm', FormCustomFormTemplateConfirmController)
