// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import '@/controllers';

// import '@/modules/sentry';

var $ = require('jquery');

document.addEventListener('turbo:load', () => {
  $("input").keydown(function(e) {
    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
      return false;
    } else {
      return true;
    }
  });
});
