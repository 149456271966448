import React, { useState } from 'react';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from './FontAwesomeIcon';
import {
  getRandomId,
} from './utils';

export type Props = {
  addElem: () => void,
  label?: string,
  hidden?: boolean,
};

export const Add: React.FC<Props> = ({
  addElem,
  label,
  hidden,
}) => {
  const [elementId] = useState(getRandomId());

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <div id={`${elementId}_add`}>
        <FontAwesomeIcon
          icon={faPlusSquare}
          onClick={() => {
            addElem();
          }}
        />
      </div>
      <UncontrolledTooltip
        placement="top"
        target={`${elementId}_add`}
      >
        {label || 'Crate new form element'}
      </UncontrolledTooltip>
    </div>
  );
};
