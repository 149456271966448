import { shortAnswerInputs } from './shortAnswerInputs';
import { longAnswerInputs } from './longAnswerInputs';
import { numberInputs } from './numberInputs';
import { arrayInputs } from './arrayInputs';
import { fileSelectInputs } from './fileSelectInputs';
import { defaultInputs } from './defaultInputs';
import { referenceInputs } from './referenceInputs';
import type { FormInput } from '../types';

export const DEFAULT_FORM_INPUTS: Record<string, FormInput> = ({
  ...defaultInputs,
  ...referenceInputs,
  ...shortAnswerInputs,
  ...longAnswerInputs,
  ...numberInputs,
  ...arrayInputs,
  ...fileSelectInputs,
});
