import { Controller } from '@hotwired/stimulus';

var $ = require('jquery');

// Connects to data-controller='form-submit'
export default class extends Controller {
  static targets = ['form', 'submitBtn']

  submit() {
    if (!this.submitBtnTarget.disabled) {
      this.submitBtnTarget.disabled = true
      this.submitBtnTarget.innerHTML = this.createSendingHtml()

      const input = this.createSubmitHidden(this.submitBtnTarget.name)
      this.formTarget.appendChild(input);
      this.formTarget.requestSubmit(this.submitBtnTarget);
    }
  }

  private createSendingHtml() {
    return `
      <div style='display: flex; justify-content: center;'>
        <div>送信中</div>
        <div id='circleG' style='margin-top: 7px;'>
          <div id='circleG_1' class='circleG'></div>
          <div id='circleG_2' class='circleG'></div>
          <div id='circleG_3' class='circleG'></div>
        </div>
      </div>
    `
  }

  private createSubmitHidden(name) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', 'true');

    return input
  }
}
