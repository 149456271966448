import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-password"
export default class extends Controller {
  static targets = ['input', 'button'];

  toggle() {
    if (this.inputTarget.type === 'password') {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.inputTarget.type = 'text'
    this.buttonTarget.classList.remove('eye-slash');
    this.buttonTarget.classList.add('eye');
  }

  hide() {
    this.inputTarget.type = 'password'
    this.buttonTarget.classList.remove('eye');
    this.buttonTarget.classList.add('eye-slash');
  }

}