import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='toggle-required'
export default class extends Controller {
  static targets = ['required'];
  static values = { toggle: String, required: String};
  static classes = ['required'];

  connect() {
    if (this.toggleValue === this.requiredValue) {
      this.required()
    } else {
      this.optional()
    }
  }

  toggle(event) {
    if (event.currentTarget.value === this.requiredValue) {
      this.required()
    } else {
      this.optional()
    }
  }

  required() {
    if (this.hasRequiredTarget) {
      this.requiredTarget.classList.add(this.requiredClass)
    }
  }

  optional() {
    if (this.hasRequiredTarget) {
      this.requiredTarget.classList.remove(this.requiredClass)
    }
  }
}
