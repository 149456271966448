import React from 'react';
import Select from 'react-select';
import { Input, FormGroup } from 'reactstrap';
import classnames from 'classnames';
import { FBRadioGroup } from './radio/FBRadioGroup';
import { GeneralParameterInputs } from './GeneralParameterInputs';
import {
  defaultUiProps,
  defaultDataProps,
  categoryToNameMap,
  categoryType,
  subtractArray,
  getRandomId,
  fetchLabel,
} from './utils';
import {
  Parameters,
  FormInput,
  Mods,
} from './types';

export type Props = {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
  mods?: Mods;
  allFormInputs: Record<string, FormInput>;
  /* eslint-disable-next-line react/no-unused-prop-types */
  showObjectNameInput?: boolean;
};

export const CardGeneralParameterInputs = ({
  parameters,
  onChange,
  allFormInputs,
  mods,
  showObjectNameInput,
}: Props) => {
  const [nameState, setNameState] = React.useState(parameters.name);
  const [titleState, setTitleState] = React.useState(parameters.title);
  const [descriptionState, setDescriptionState] = React.useState(
    parameters.description,
  );
  const [pardotState, setPardotState] = React.useState(parameters.pardot);
  const [requiredState, setRequiredState] = React.useState(
    parameters.required,
  );
  const [validateFormatState, setValidateFormatState] = React.useState(
    parameters.validateFormat,
  );
  const [elementId] = React.useState(getRandomId());
  const categoryMap = categoryToNameMap(parameters.category, allFormInputs);

  const objectNameLabel  = fetchLabel('objectNameLabel',  'Object Name', mods);
  const displayNameLabel = fetchLabel('displayNameLabel', 'Display Name', mods);
  const pardotNameLabel  = fetchLabel('pardotNameLabel',  'Pardot Name', mods);
  const descriptionLabel = fetchLabel('descriptionLabel', 'Description', mods);
  const inputTypeLabel   = fetchLabel('inputTypeLabel',   'Input Type', mods);
  const requiredLabel    = fetchLabel('requiredLabel',    'Required', mods);
  const noLabel          = fetchLabel('noLabel',  'No', mods);
  const yesLabel         = fetchLabel('yesLabel', 'No', mods);
  const validateLabel = fetchLabel('validateLabel', 'Validate', mods);
  const validateNoLabel = fetchLabel('validateNoLabel', 'No', mods);
  const validateMailLabel = fetchLabel('validateMailLabel', 'email', mods);
  const validatePhoneLabel = fetchLabel('validatePhoneLabel', 'phone', mods);
  const validateKanaLabel = fetchLabel('validateKanaLabel', 'kana', mods);

  const availableInputTypes = () => {
    const definitionsInSchema =
      parameters.definitionData &&
      Object.keys(parameters.definitionData).length !== 0;
    // Hide the "Reference" option if there are no definitions in the schema
    let inputKeys = Object.keys(categoryMap).filter(
      (key) => key !== 'ref' || definitionsInSchema,
    );
    // Exclude hidden inputs based on mods
    if (mods) inputKeys = subtractArray(inputKeys, mods.deactivatedFormInputs);
    if (mods && mods.formInputsOrder && Array.isArray(mods.formInputsOrder)) {
      inputKeys = [
        ...mods.formInputsOrder,
        ...(inputKeys.filter((key) =>
          !mods.formInputsOrder?.includes(key))),
      ];

      return inputKeys
        .map((key) => ({
          value: key,
          label: categoryMap[key],
        }));
    }
    return inputKeys
      .map((key) => ({
        value: key,
        label: categoryMap[key],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <>
      <div className="card-entry-row">
        <div
          className={classnames('card-entry')}
        >
          <h5>
            {objectNameLabel}
          </h5>
          <Input
            value={nameState || ''}
            type="text"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setNameState(ev.target.value)}
            onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
              onChange({ ...parameters, name: ev.target.value });
            }}
            className="card-text"
          />
        </div>
      </div>
      <div className="card-entry-row">
        <div
          className={classnames('card-entry')}
        >
          <h5>
            {displayNameLabel}
          </h5>
          <Input
            value={titleState || ''}
            type="text"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setTitleState(ev.target.value)}
            onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
              onChange({ ...parameters, title: ev.target.value });
            }}
            className="card-text"
          />
        </div>
      </div>
      <div className="card-entry-row">
        <div
          className={classnames('card-entry')}
        >
          <h5>
            {pardotNameLabel}
          </h5>
          <Input
            value={pardotState || ''}
            type="text"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setPardotState(ev.target.value)}
            onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
              onChange({ ...parameters, pardot: ev.target.value });
            }}
            className="card-text"
          />
        </div>
      </div>
      <div className="card-entry-row">
        <div
          className={classnames('card-entry', {
            'wide-card-entry': !showObjectNameInput,
          })}
        >
          <h5>
            {inputTypeLabel}
          </h5>
          <Select
            value={{
              value: parameters.category,
              label: categoryMap[parameters.category],
            }}
            options={availableInputTypes()}
            onChange={(val: any) => {
              // figure out the new 'type'
              const newCategory = val.value;
              const newProps: any = {
                ...defaultUiProps(newCategory, allFormInputs),
                ...defaultDataProps(newCategory, allFormInputs),
                name: parameters.name,
                required: parameters.required,
              };

              if (newProps.$ref !== undefined && !newProps.$ref) {
                // assign an initial reference
                const firstDefinition = Object.keys(
                  parameters.definitionData,
                )[0];
                newProps.$ref = `#/definitions/${firstDefinition || 'empty'}`;
              }

              onChange({
                ...newProps,
                title: newProps.title || parameters.title,
                default: newProps.default || '',
                type: newProps.type || categoryType(newCategory, allFormInputs),
                category: newProps.category || newCategory,
              });
            }}
            className="card-select"
          />
        </div>
      </div>
      <div className="card-entry-row">
        <div
          className={`card-entry wide-card-entry ${parameters.$ref ? 'disabled-input' : ''}`}
        >
          <h5>
            {descriptionLabel}
          </h5>
          <FormGroup>
            <Input
              value={descriptionState || ''}
              type="textarea"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                setDescriptionState(ev.target.value)}
              onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
                onChange({ ...parameters, description: ev.target.value });
              }}
              className="card-textarea"
            />
          </FormGroup>
        </div>
      </div>
      <div className="card-entry-row">
        <div
          className={classnames('card-entry', 'wide-card-entry')}
        >
          <h5>
            {requiredLabel}
          </h5>
          <FBRadioGroup
            defaultValue={requiredState ? 'yes' : 'no'}
            horizontal
            options={[
              {
                value: 'no',
                label: noLabel,
              },
              {
                value: 'yes',
                label: yesLabel,
              },
            ]}
            onChange={(selection) => {
              setRequiredState(selection === 'yes');
              onChange({ ...parameters, required: selection === 'yes' });
            }}
            id={`${elementId}_required`}
          />
        </div>
      </div>

      <div className="card-entry-row">
        <div
          className={classnames('card-entry', 'wide-card-entry')}
        >
          <h5>
            {validateLabel}
          </h5>
          <FBRadioGroup
            defaultValue = { validateFormatState || 'no' }
            horizontal
            options={[
              {
                value: 'no',
                label: validateNoLabel,
              },
              {
                value: 'mail',
                label: validateMailLabel,
              },
              {
                value: 'phone',
                label: validatePhoneLabel,
              },
              {
                value: 'kana',
                label: validateKanaLabel,
              },
            ]}
            onChange={(selection) => {
              setValidateFormatState(selection);
              onChange({ ...parameters, validateFormat: selection });
            }}
            id={`${elementId}_validateFormat`}
          />
        </div>
      </div>

      <div className="card-category-options">
        <GeneralParameterInputs
          category={parameters.category}
          parameters={parameters}
          onChange={onChange}
          mods={mods}
          allFormInputs={allFormInputs}
        />
      </div>
    </>
  );
};
