import React from 'react';
import { createRoot } from 'react-dom/client';
import { Controller } from '@hotwired/stimulus';

import { SurveyBuilder } from '@/components/organisms/SurveyBuilder';

// Connects to data-controller="survey--builder"
export default class extends Controller {
  // @ts-ignore
  declare labelsValue;

  // @ts-ignore
  declare newElementTitleValue;

  // @ts-ignore
  declare formInputsOverrideValue;

  // @ts-ignore
  declare tooltipDescriptionsValue;

  // @ts-ignore
  declare domRoot;

  static values = {
    labels: Object,
    tooltipDescriptions: Object,
    newElementTitle: String,
    formInputsOverride: Object,
  };

  static targets = ['schema', 'uiSchema', 'canvas'];

  connect() {
    const { schemaTarget, uiSchemaTarget } = this;

    this.domRoot = createRoot(this.canvas)
    this.domRoot.render(
      <SurveyBuilder
        schema={schemaTarget.value}
        uischema={uiSchemaTarget.value}
        onChange={this.onChange}
        labels={this.labelsValue}
        tooltipDescriptions={this.tooltipDescriptionsValue}
        newElementTitle={this.newElementTitleValue}
        formInputsOverride={this.formInputsOverrideValue}
      />
    );
  }

  disconnect() {
    this.domRoot.unmount()
  }

  onChange = (newSchema: string, newUiSchema: string) => {
    this.schemaTarget.value = newSchema;
    this.uiSchemaTarget.value = newUiSchema;
  };

  get schemaTarget() {
    return this.targets.find('schema') as HTMLInputElement;
  }

  get uiSchemaTarget() {
    return this.targets.find('uiSchema') as HTMLInputElement;
  }

  get canvas() {
    return this.targets.find('canvas') as HTMLElement;
  }
}
