import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from "@rails/activestorage";

import "inline-attachment/src/inline-attachment";
import "inline-attachment/src/codemirror-4.inline-attachment";
// import 'simplemde/dist/simplemde.min.css'
import SimpleMDE from "simplemde";

export default class extends Controller {
  static targets = ['input', 'code-editor'];

  connect() {
    const metaElem = document.querySelector("meta[name='csrf-token']")
    const csrfToken = metaElem.getAttribute("content")

    const simplemde = new SimpleMDE({
      element: this.codeEditorTarget,
      toolbar: false,
    });

    inlineAttachment.editors.codemirror4.attach(simplemde.codemirror, {
      urlText: '<img src="{filename}">',
      uploadUrl: "/uploader/image",
      uploadFieldName: "asset[file]",
      allowedTypes: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
      extraHeaders: { "X-CSRF-Token": csrfToken },
    });
  }

  uploadFile() {
    Array.from(this.inputTarget.files).forEach((file) => {
      const upload = new DirectUpload(
        file,
        this.inputTarget.dataset.directUploadUrl,
        this // callback directUploadWillStoreFileWithXHR(request)
      );
      upload.create((error, blob) => {
        if (error) {
          console.log(error);
        } else {
          this.createHiddenBlobInput(blob);
          // if you're not submitting a form after upload. you need to attach
          // uploaded blob to some model here and skip hidden input.
        }
      });
    });
  }
}
