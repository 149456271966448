import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="additional-input-form"
export default class extends Controller {
  static targets = ['copyFrom', 'copyTo']

  connect() {
    this.copyFromTarget.disabled = true
  }

  add(event) {
    const inputElement = this.copyFromTarget.cloneNode(true)
    inputElement.disabled = false
    this.copyToTarget.append(inputElement)
  }
}
