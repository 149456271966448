import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggle', 'disclosure', 'closure'];
  static values = { toggle: Boolean };
  static classes = ['hide'];

  connect() {
    if (this.toggleValue) {
      this.disclosureShow()
      this.closureHide()
    } else {
      this.disclosureHide()
      this.closureShow()
    }
  }

  toggle(evt) {
    this.disclosureToggle()
    this.closureToggle()
  }

  show(evt) {
    this.disclosureShow()
    this.closureHide()
  }

  hide(evt) {
    this.disclosureHide()
    this.closureShow()
  }

  disclosureShow() {
    if (this.hasDisclosureTarget) {
      this.disclosureTargets.forEach((el) => {
        el.classList.remove(this.hideClass)
        el.disabled = false
      })
    }
  }

  disclosureHide() {
    if (this.hasDisclosureTarget) {
      this.disclosureTargets.forEach((el) => {
        el.classList.add(this.hideClass)
        el.disabled = true
      })
    }
  }

  disclosureToggle() {
    if (this.hasDisclosureTarget) {
      this.disclosureTargets.forEach((el) => {
        el.classList.toggle(this.hideClass)
        el.disabled ^= el.disabled
      })
    }
  }

  closureShow() {
    if (this.hasClosureTarget) {
      this.closureTargets.forEach((el) => {
        el.classList.remove(this.hideClass)
        el.disabled = false
      })
    }
  }

  closureHide() {
    if (this.hasClosureTarget) {
      this.closureTargets.forEach((el) => {
        el.classList.add(this.hideClass)
        el.disabled = true
      })
    }
  }

  closureToggle() {
    if (this.hasClosureTarget) {
      this.closureTargets.forEach((el) => {
        el.classList.toggle(this.hideClass)
        el.disabled ^= el.disabled
      })
    }
  }
}
