import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='entry--file-select'
export default class extends Controller {
  static targets = ['file', 'filename', 'delete', 'hiddenValue'];
  static classes = ['hide'];
  static values = {
    'filename': String
  };

  connect() {
    if (this.filenameValue) {
      this.deleteTarget.classList.remove(this.hideClass)
    }
  }

  select(evt) {
    const file = evt.target.files[0]
    if (!file) { return false }

    const filename = file.name
    this.filenameTarget.innerHTML = filename

    this.deleteTarget.classList.remove(this.hideClass)
  }

  remove(evt) {
    this.fileTarget.value = ''
    this.filenameTarget.innerHTML = ''
    this.hiddenValueTarget.innerHTML = ''
    this.deleteTarget.classList.add(this.hideClass)
  }
}
