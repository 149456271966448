import { Controller } from '@hotwired/stimulus';

var $ = require('jquery');

// Connects to data-controller='form--article-template-confirm'
export default class extends Controller {
  static targets = ['form', 'selectTemplate']
  static values = {
    'current': String,
    'message': String,
  }

  submit() {
    const onConfirm = this.checkNeedConfirm()
    const onSubmit = !onConfirm || confirm(this.messageValue)

    if (onSubmit) {
      this.formTarget.submit();
    }
  }

  private checkNeedConfirm() {
    const change = this.selectTemplateTarget.value
    const current = this.currentValue

    return current && change
  }
}
