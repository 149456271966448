import React, { useState, useEffect } from 'react';

export const fileSelectInputs: Record<string, FormInput> = {
  fileSelect: {
    displayName: 'File Select',
    matchIf: [
      {
        types: ['file'],
        widget: 'file',
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {
      'ui:widget': 'file',
    },
    type: 'file',
  },
};
