import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
var Modal = require('@/site_fronts/modaal.js');

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['container']
  static values = {
                    initOpen: Boolean,
                    closeLock: Boolean,
                  };

  connect() {
    Modaal()
    const $this = $(this.containerTarget)
    $this.modaal({
      overlay_opacity: 0.6,
    });

    if (this.initOpenValue) {
      $this.modaal('open')
    }
  }

  open(event) {
    this.modal.show()
  }

  close(event) {
    if (this.closeLockValue) {
      this.modal.hide()
    }
  }
}